










  import { SnippetCallbacks, SnippetConfig, use$x, XEvent } from '@empathyco/x-components';
  import { Tagging } from '@empathyco/x-components/tagging';
  import { UrlHandler } from '@empathyco/x-components/url';
  import { SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
  import { QueryPreviewInfo } from '@empathyco/x-components/queries-preview';
  import {
    computed,
    ComputedRef,
    defineComponent,
    getCurrentInstance,
    inject,
    onBeforeUnmount,
    provide,
    ref,
    watch
  } from 'vue';
  import { useDevice } from './composables/use-device.composable';
  import { isIOS, removeSearchInputFocus } from './composables/use-is-ios-composable';
  import currencies from './i18n/currencies';
  import './tailwind/index.css';

  export default defineComponent({
    components: {
      SnippetCallbacks,
      SnippetConfigExtraParams,
      Tagging,
      UrlHandler,
      MainModal: () => import('./components/custom-main-modal.vue').then(m => m.default)
    },
    setup() {
      const isOpen = ref(false);
      const x = use$x();
      const { deviceName } = useDevice();
      const appInstance = getCurrentInstance();
      const excludedExtraParams = [
        'callbacks',
        'uiLang',
        'consent',
        'documentDirection',
        'currency',
        'filters',
        'isolate',
        'queriesPreview',
        'wishlist'
      ];
      const snippetConfig = inject<SnippetConfig>('snippetConfig')!;
      const openXEvents = ['UserOpenXProgrammatically', 'UserClickedOpenX'];

      openXEvents.forEach(event =>
        x.on(event as XEvent, false).subscribe(() => (isOpen.value = true))
      );
      const documentDirection = computed(() => {
        return (
          document.documentElement.dir ||
          document.body.dir ||
          (snippetConfig.documentDirection ?? 'ltr')
        );
      });

      const currencyFormat = computed(() => currencies[snippetConfig.currency!]);
      provide<string>('currencyFormat', currencyFormat.value);

      const queriesPreviewInfo = computed(() => snippetConfig.queriesPreview ?? []);
      provide<ComputedRef<QueryPreviewInfo[]>>('queriesPreviewInfo', queriesPreviewInfo);

      watch(
        () => snippetConfig.uiLang,
        uiLang => appInstance?.proxy.$setLocale(uiLang ?? 'en')
      );

      watch(deviceName, device => appInstance?.proxy.$setLocaleDevice(device));

      //fix keyboard issue on iOS
      if (isIOS()) {
        document.addEventListener('touchmove', removeSearchInputFocus);
        onBeforeUnmount(() => {
          document.removeEventListener('touchmove', removeSearchInputFocus);
        });
      }

      return {
        isOpen,
        excludedExtraParams,
        documentDirection
      };
    }
  });
